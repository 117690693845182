import http from './http'

// let baseUrl = 'http://erp.topc.cc/index.php'
// let baseUrl = '/index.php'//测试服
let baseUrl = ''//本地
// let baseUrl = 'https://erp.phantomcreekestates.com/index.php'//线上
if (process.env.NODE_ENV == "development") {
    //开发环境
    baseUrl = '/api/index.php'
} else {
    //生产环境
    baseUrl = 'https://erp.phantomcreekestates.com/index.php'//正式服
    // baseUrl = '/index.php'//测试服
}

export function termsService(params){return http.get(`${baseUrl}/index.php/termsService`,params)}//服务协议
export function editpwd(params){return http.post(`${baseUrl}/editpwd`,params)}//修改密码
export function upfile(params){return http.upImgPost(`${baseUrl}/upfile`,params)}//上传图片
export function usermenu(params){return http.post(`${baseUrl}/usermenu`,params)}//菜单配置
export function loginApi(params){return http.post(`${baseUrl}/login`,params)}//登录
export function addUser(params){return http.post(`${baseUrl}/addUser`,params)}//新增用户
export function editUser(params){return http.post(`${baseUrl}/editUser`,params)}//编辑用户
export function user(params){return http.post(`${baseUrl}/user`,params)}//获取用户
export function deleteUser(params){return http.post(`${baseUrl}/deleteUser`,params)}//删除用户
export function getDept(params){return http.post(`${baseUrl}/getDept`,params)}//获取部门
export function addDept(params){return http.post(`${baseUrl}/addDept`,params)}//新增部门
export function deleteDept(params){return http.post(`${baseUrl}/deleteDept`,params)}//删除部门
export function editDept(params){return http.post(`${baseUrl}/editDept`,params)}//编辑部门
export function getPosition(params){return http.post(`${baseUrl}/getPosition`,params)}//获取职位
export function addPosition(params){return http.post(`${baseUrl}/addPosition`,params)}//新增职位
export function editPosition(params){return http.post(`${baseUrl}/editPosition`,params)}//编辑职位
export function deletePosition(params){return http.post(`${baseUrl}/deletePosition`,params)}//删除职位
export function getAddress(params){return http.post(`${baseUrl}/getAddress`,params)}//获取收货地址
export function addAddress(params){return http.post(`${baseUrl}/addAddress`,params)}//添加收货地址
export function deleteAddress(params){return http.post(`${baseUrl}/deleteAddress`,params)}//删除收货地址
export function editAddress(params){return http.post(`${baseUrl}/editAddress`,params)}//编辑收货地址
export function getStorehouse(params){return http.post(`${baseUrl}/getStorehouse`,params)}//获取仓库
export function addStorehouse(params){return http.post(`${baseUrl}/addStorehouse`,params)}//添加仓库
export function deleteStorehouse(params){return http.post(`${baseUrl}/deleteStorehouse`,params)}//删除仓库
export function editStorehouse(params){return http.post(`${baseUrl}/editStorehouse`,params)}//删除仓库
export function getRegion(params){return http.post(`${baseUrl}/getRegion`,params)}//获取区域
export function addRegion(params){return http.post(`${baseUrl}/addRegion`,params)}//添加区域
export function editRegion(params){return http.post(`${baseUrl}/editRegion`,params)}//编辑区域
export function deleteRegion(params){return http.post(`${baseUrl}/deleteRegion`,params)}//删除区域

export function getRequestfunds(params){return http.post(`${baseUrl}/getRequestfunds`,params)}//获取请款单
export function hiddenRequestfunds(params){return http.post(`${baseUrl}/hiddenRequestfunds`,params)}//删除请款单通知
export function deleteRequestfunds(params){return http.post(`${baseUrl}/deleteRequestfunds`,params)}//删除请款单
export function editRequestfunds(params){return http.post(`${baseUrl}/editRequestfunds`,params)}//编辑请款单
export function addRequestfunds(params){return http.post(`${baseUrl}/addRequestfunds`,params)}//添加请款单
export function check(params){return http.post(`${baseUrl}/check`,params)}//审批
export function getSupplierProduct(params){return http.post(`${baseUrl}/getSupplierProduct`,params)}//根据供货商获取请款单产品
export function editPaystatus(params){return http.post(`${baseUrl}/editPaystatus`,params)}//修改请款单状态

export function getProduct(params){return http.post(`${baseUrl}/getProduct`,params)}//获取产品
export function addProduct(params){return http.post(`${baseUrl}/addProduct`,params)}//添加产品
export function deleteProduct(params){return http.post(`${baseUrl}/deleteProduct`,params)}//删除产品
export function editProduct(params){return http.post(`${baseUrl}/editProduct`,params)}//编辑产品
export function editonoff(params){return http.post(`${baseUrl}/editonoff`,params)}//修改产品上架下架状态

export function getPurchase(params){return http.post(`${baseUrl}/getPurchase`,params)}//获取采购单
export function caigou_list(params){return http.post(`${baseUrl}/caigou_list`,params)}//获取采购单 （20240406)
export function deletePurchase(params){return http.post(`${baseUrl}/deletePurchase`,params)}//删除采购单
export function addPurchase(params){return http.post(`${baseUrl}/addPurchase`,params)}//添加采购单
export function editPurchase(params){return http.post(`${baseUrl}/editPurchase`,params)}//编辑采购单

export function getPrudoctByWarehouse(params){return http.post(`${baseUrl}/getPrudoctByWarehouse`,params)}//根据仓库获取产品

export function getInstorehouse(params){return http.post(`${baseUrl}/getInstorehouse`,params)}//获取入库
export function addInstorehouse(params){return http.post(`${baseUrl}/addInstorehouse`,params)}//添加入库
export function editInstorehouse(params){return http.post(`${baseUrl}/editInstorehouse`,params)}//编辑入库
export function deleteInstorehouse(params){return http.post(`${baseUrl}/deleteInstorehouse`,params)}//删除入库

export function getOutstorehouse(params){return http.post(`${baseUrl}/getOutstorehouse`,params)}//获取出库单
export function addOutstorehouse(params){return http.post(`${baseUrl}/addOutstorehouse`,params)}//添加出库单
export function deleteOutstorehouse(params){return http.post(`${baseUrl}/deleteOutstorehouse`,params)}//删除出库单
export function editOutstorehouse(params){return http.post(`${baseUrl}/editOutstorehouse`,params)}//删除出库单
export function OutStatus(params){return http.post(`${baseUrl}/OutStatus`,params)}//修改出库单状态

export function getStock(params){return http.post(`${baseUrl}/getStock`,params)}//库存列表
export function getStockInfo(params){return http.post(`${baseUrl}/getStockInfo`,params)}//库存详情

export function getTransfer(params){return http.post(`${baseUrl}/getTransfer`,params)}//获取库存转移
export function deleteTransfer(params){return http.post(`${baseUrl}/deleteTransfer`,params)}//删除库存转移
export function editTransfer(params){return http.post(`${baseUrl}/editTransfer`,params)}//编辑库存转移
export function addTransfer(params){return http.post(`${baseUrl}/addTransfer`,params)}//添加库存转移
export function TransferStatus(params){return http.post(`${baseUrl}/TransferStatus`,params)}//添加库存转移

export function getSupplier(params){return http.post(`${baseUrl}/getSupplier`,params)}//获取供货商
export function addSupplier(params){return http.post(`${baseUrl}/addSupplier`,params)}//添加供货商
export function deleteSupplier(params){return http.post(`${baseUrl}/deleteSupplier`,params)}//添加供货商
export function editSupplier(params){return http.post(`${baseUrl}/editSupplier`,params)}//添加供货商

export function getGlnumber(params){return http.post(`${baseUrl}/getGlnumber`,params)}//获取GLnumber
export function deleteGlnumber(params){return http.post(`${baseUrl}/deleteGlnumber`,params)}//删除GLnumber
export function editGlnumber(params){return http.post(`${baseUrl}/editGlnumber`,params)}//编辑GLnumber
export function addGlnumber(params){return http.post(`${baseUrl}/addGlnumber`,params)}//添加GLnumber

export function getbyBarcode(params){return http.post(`${baseUrl}/getbyBarcode`,params)}//根据barcode获取产品

export function getCustomer(params){return http.post(`${baseUrl}/getCustomer`,params)}//获取客户
export function addCustomer(params){return http.post(`${baseUrl}/addCustomer`,params)}//添加客户
export function editCustomer(params){return http.post(`${baseUrl}/editCustomer`,params)}//编辑客户
export function deleteCustomer(params){return http.post(`${baseUrl}/deleteCustomer`,params)}//删除客户

export function addPay(params){return http.post(`${baseUrl}/addPay`,params)}//添加付款设置
export function getPay(params){return http.post(`${baseUrl}/getPay`,params)}//获取付款设置
export function editPay(params){return http.post(`${baseUrl}/editPay`,params)}//编辑付款设置
export function deletePay(params){return http.post(`${baseUrl}/deletePay`,params)}//删除付款设置

export function getTax(params){return http.post(`${baseUrl}/getTax`,params)}//获取税务
export function getTaxInfo(params){return http.post(`${baseUrl}/getTaxInfo`,params)}//税务详情
export function addTax(params){return http.post(`${baseUrl}/addTax`,params)}//添加税务
export function editTax(params){return http.post(`${baseUrl}/editTax`,params)}//编辑税务
export function deleteTax(params){return http.post(`${baseUrl}/deleteTax`,params)}//编辑税务

export function getOrder(params){return http.post(`${baseUrl}/getOrder`,params)}//获取订单
export function addOrder(params){return http.post(`${baseUrl}/addOrder`,params)}//新增订单
export function editOrder(params){return http.post(`${baseUrl}/editOrder`,params)}//编辑订单
export function deleteOrder(params){return http.post(`${baseUrl}/deleteOrder`,params)}//删除订单
export function editstatus(params){return http.post(`${baseUrl}/editstatus`,params)}//修改订单状态

export function getPorductClass(params){return http.post(`${baseUrl}/getPorductClass`,params)}//获取产品分类
export function getByclass(params){return http.post(`${baseUrl}/getByclass`,params)}//更具产品获取分类
export function getbaobiao(params){return http.post(`${baseUrl}/getbaobiao`,params)}//获取报表
export function exportBaobiao(params){return http.post(`${baseUrl}/exportBaobiao`,params)}//报表导出excel
export function promotebb(params){return http.post(`${baseUrl}/promotebb`,params)}//promote获取报表
export function exp_promotebb(params){return http.post(`${baseUrl}/exp_promotebb`,params)}//promote报表导出excel
export function ck_all(params){return http.post(`${baseUrl}/ck_all`,params)}//仓库汇总报表导出excel
export function ck_list(params){return http.post(`${baseUrl}/ck_list`,params)}//仓库明细报表导出excel
export function yjbb(params){return http.post(`${baseUrl}/yjbb`,params)}//用酒表导出excel
export function scyjslbb(params){return http.post(`${baseUrl}/scyjslbb`,params)}//市场用酒数量报表导出excel
export function kpslbb(params){return http.post(`${baseUrl}/kpslbb`,params)}//开瓶数量报表excel

export function getReminder(params){return http.post(`${baseUrl}/getReminder`,params)}//小铃铛
export function purchasecp(params){return http.post(`${baseUrl}/purchasecp`,params)}//获取在某供应商处已采购过的商品
export function getBybarcodeStock(params){return http.post(`${baseUrl}/getBybarcode_stock`,params)}//获取在某供应商处已采购过的商品
export function Requestbaobiao(params){return http.post(`${baseUrl}/Requestbaobiao`,params)}//请款报表
export function RequestexportBaobiao(params){return http.post(`${baseUrl}/RequestexportBaobiao`,params)}//导出请款报表
export function editStock(params){return http.post(`${baseUrl}/editStock`,params)}//库存列表增加修改功能，只能修改产品名称和barcode。
export function service(params){return http.post(`${baseUrl}/service`,params)}//维修模块新增
export function serviceList(params){return http.post(`${baseUrl}/serviceList`,params)}//维修模块列表查询
export function serviceEdit(params){return http.post(`${baseUrl}/serviceEdit`,params)}//维修模块编辑
export function serviceDel(params){return http.post(`${baseUrl}/serviceDel`,params)}//维修模块删除
export function serviceDateLog(params){return http.post(`${baseUrl}/serviceDateLog`,params)}//维修根据日期查询最近上一条记录
export function stat(params){return http.post(`${baseUrl}/stat`,params)}//首页数据统计
export function statView(params){return http.post(`${baseUrl}/statView`,params)}//首页数据统计
export function getpurchaselist(params){return http.post(`${baseUrl}/getpurchaselist`,params)}//获取通过审批的请购列表
export function getproductclss(params){return http.post(`${baseUrl}/getproductClss`,params)}//获取产品分类
export function cw4data(params){return http.post(`${baseUrl}/cw4data`,params)}//财务首页统计顶部4个小方块数据
export function boss(params){return http.post(`${baseUrl}/boss`,params)}//老板首页顶部接口
export function xsview(params){return http.post(`${baseUrl}/xsview`,params)}//新销售页面统计接口
export function xsstat(params){return http.post(`${baseUrl}/xsstat`,params)}//
export function kcstat(params){return http.post(`${baseUrl}/kcstat`,params)}//产品总数 ，库存数量价格统计接口
export function cthd1(params){return http.post(`${baseUrl}/cthd1`,params)}//餐厅+活动 1 四个数据对比
export function cthd2(params){return http.post(`${baseUrl}/cthd2`,params)}//餐厅+活动人工成本统计
export function cthd3(params){return http.post(`${baseUrl}/cthd3`,params)}//餐厅+活动 过去十五天销售和人工成本统计
export function cthd4(params){return http.post(`${baseUrl}/cthd4`,params)}//餐厅+ 活动 最受欢迎产品 销售人员排行
export function cthd5(params){return http.post(`${baseUrl}/cthd5`,params)}//餐厅+活动 底部 人工成本，电成本，天然气成本统计
export function cthd6(params){return http.post(`${baseUrl}/cthd6`,params)}//昨日人工详情数据
export function cthd7(params){return http.post(`${baseUrl}/cthd7`,params)}//品酒室新增图表统计
export function cthd8(params){return http.post(`${baseUrl}/cthd8`,params)}//品酒室 餐厅 人均服务数量
export function cthd10(params){return http.post(`${baseUrl}/cthd10`,params)}//品酒室 餐厅 人均服务数量
export function pj1(params){return http.post(`${baseUrl}/pj1`,params)}//品酒
export function pj2(params){return http.post(`${baseUrl}/pj2`,params)}//品酒
export function main1(params){return http.post(`${baseUrl}/main1`,params)}//main1
export function jlb1(params){return http.post(`${baseUrl}/jlb1`,params)}//main1
export function jlb2(params){return http.post(`${baseUrl}/jlb2`,params)}//main1
export function apRunBaobiao(params){return http.post(`${baseUrl}/aprunbaobiao`,params)}//报表
export function apDeriveRequestexportBaobiao(params){return http.post(`${baseUrl}/apDeriveRequestexportBaobiao`,params)}//导出aprun报表
export function cllist(params){return http.post(`${baseUrl}/cllist`,params)}//材料列表
export function cldel(params){return http.post(`${baseUrl}/cldel`,params)}//材料删除
export function cladd(params){return http.post(`${baseUrl}/cladd`,params)}//材料添加
export function cledit(params){return http.post(`${baseUrl}/cledit`,params)}//材料编辑
export function zclist(params){return http.post(`${baseUrl}/zclist`,params)}//资产列表
export function zcdel(params){return http.post(`${baseUrl}/zcdel`,params)}//资产删除
export function zcadd(params){return http.post(`${baseUrl}/zcadd`,params)}//资产添加
export function zcedit(params){return http.post(`${baseUrl}/zcedit`,params)}//资产编辑
export function zcnoteadd(params){return http.post(`${baseUrl}/zcnoteadd`,params)}//资产添加附件
export function zcnotedel(params){return http.post(`${baseUrl}/zcnotedel`,params)}//资产删除附件
export function clblist(params){return http.post(`${baseUrl}/clblist`,params)}//材料表
export function clbadd(params){return http.post(`${baseUrl}/clbadd`,params)}//材料表新增
export function clbedit(params){return http.post(`${baseUrl}/clbedit`,params)}//材料表编辑
export function clbdel(params){return http.post(`${baseUrl}/clbdel`,params)}//材料表删除

export function inventorylist(params){return http.post(`${baseUrl}/inventorylist`,params)}//盘点列表
export function inventoryadd(params){return http.post(`${baseUrl}/inventoryadd`,params)}//添加盘点
export function inventoryedit(params){return http.post(`${baseUrl}/inventoryedit`,params)}//修改盘点
export function inventorydel(params){return http.post(`${baseUrl}/inventorydel`,params)}//删除盘点

export function zcclasslist(params){return http.post(`${baseUrl}/zcclasslist`,params)}//资产分类列表
export function zcclassadd(params){return http.post(`${baseUrl}/zcclassadd`,params)}//添加资产分类
export function zcclassedit(params){return http.post(`${baseUrl}/zcclassedit`,params)}//修改资产分类
export function zcclassdel(params){return http.post(`${baseUrl}/zcclassdel`,params)}//删除资产分类

export function jjlist(params){return http.post(`${baseUrl}/jjlist`,params)}//酒局列表
export function jjadd(params){return http.post(`${baseUrl}/jjadd`,params)}//酒局新增
export function jjdel(params){return http.post(`${baseUrl}/jjdel`,params)}//酒局删除
export function jjedit(params){return http.post(`${baseUrl}/jjedit`,params)}//酒局编辑

export function pinfolist(params){return http.post(`${baseUrl}/pinfolist`,params)}//自动扣库存记录列表
export function filelist(params){return http.post(`${baseUrl}/filelist`,params)}//文件分享列表
export function filedel(params){return http.post(`${baseUrl}/filedel`,params)}//文件分享删除
export function fileadd(params){return http.post(`${baseUrl}/fileadd`,params)}//文件分享新增
export function fileedit(params){return http.post(`${baseUrl}/fileedit`,params)}//文件分享编辑

export function addneworder(params){return http.post(`${baseUrl}/addneworder`,params)}//订单新增
export function editneworder(params){return http.post(`${baseUrl}/editneworder`,params)}//订单编辑
export function delneworder(params){return http.post(`${baseUrl}/delneworder`,params)}//订单删除

export function olindex(params){return http.post(`${baseUrl}/olindex`,params)}//
export function oladd(params){return http.post(`${baseUrl}/oladd`,params)}//
export function oledit(params){return http.post(`${baseUrl}/oledit`,params)}//
export function oldel(params){return http.post(`${baseUrl}/oldel`,params)}//

export function jsTransfer(params){return http.post(`${baseUrl}/jsTransfer`,params)}//
export function getStockClass(params){return http.post(`${baseUrl}/getStockClass`,params)}//库存分类列表
export function addStockClass(params){return http.post(`${baseUrl}/addStockClass`,params)}//库存分类添加
export function editStockClass(params){return http.post(`${baseUrl}/editStockClass`,params)}//库存分类编辑
export function delStockClass(params){return http.post(`${baseUrl}/delStockClass`,params)}//库存分类删除


export function zz_index(params){return http.post(`${baseUrl}/zz_index`,params)}//种植列表
export function zz_add(params){return http.post(`${baseUrl}/zz_add`,params)}//种植新增
export function zz_edit(params){return http.post(`${baseUrl}/zz_edit`,params)}//种植编辑
export function zz_del(params){return http.post(`${baseUrl}/zz_del`,params)}//种植删除

export function cc_index(params){return http.post(`${baseUrl}/cc_index`,params)}//采收列表
export function cc_add(params){return http.post(`${baseUrl}/cc_add`,params)}//采收新增
export function cc_edit(params){return http.post(`${baseUrl}/cc_edit`,params)}//采收编辑
export function cc_del(params){return http.post(`${baseUrl}/cc_del`,params)}//采收删除

export function sf_index(params){return http.post(`${baseUrl}/sf_index`,params)}//施肥列表
export function sf_add(params){return http.post(`${baseUrl}/sf_add`,params)}//施肥添加
export function sf_edit(params){return http.post(`${baseUrl}/sf_edit`,params)}//施肥编辑
export function sf_del(params){return http.post(`${baseUrl}/sf_del`,params)}//施肥删除

export function ym_index(params){return http.post(`${baseUrl}/ym_index`,params)}//疫苗列表
export function ym_add(params){return http.post(`${baseUrl}/ym_add`,params)}//疫苗添加
export function ym_edit(params){return http.post(`${baseUrl}/ym_edit`,params)}//疫苗编辑
export function ym_del(params){return http.post(`${baseUrl}/ym_del`,params)}//疫苗删除

export function ot_index(params){return http.post(`${baseUrl}/ot_index`,params)}//其他列表
export function ot_add(params){return http.post(`${baseUrl}/ot_add`,params)}//其他添加
export function ot_edit(params){return http.post(`${baseUrl}/ot_edit`,params)}//其他编辑
export function ot_del(params){return http.post(`${baseUrl}/ot_del`,params)}//其他删除

export function zz_total(params){return http.post(`${baseUrl}/zz_total`,params)}//统计
export function getStockLog(params){return http.post(`${baseUrl}/getStockLog`,params)}//库存记录
export function clcheck(params){return http.post(`${baseUrl}/clcheck`,params)}//预算表点击完成操作

export function order_add(params){return http.post(`${baseUrl}/order_add`,params)}//订单
export function order_edit(params){return http.post(`${baseUrl}/order_edit`,params)}//订单编辑
export function getStockLog2excel(params){return http.post(`${baseUrl}/getStockLog2excel`,params)}//库存记录导出
export function order_pay(params){return http.post(`${baseUrl}/order_pay`,params)}//
export function set(params){return http.post(`${baseUrl}/set`,params)}//

export function promoteAdd(params){return http.post(`${baseUrl}/promoteAdd`,params)}//promote 提交
export function promoteEdit(params){return http.post(`${baseUrl}/promoteEdit`,params)}//promote 编辑
export function promoteList(params){return http.post(`${baseUrl}/promoteList`,params)}//promote 列表
export function promoteDel(params){return http.post(`${baseUrl}/promoteDel`,params)}//promote 删除

export function xsrk(params){return http.post(`${baseUrl}/xsrk`,params)}//promote 首页销售和入库统计
