<template>
 <router-view></router-view>

</template>
<style>
 .flex{display: flex;align-items: center;}
 .flex-center{display: flex;align-items: center;justify-content: center}
 .flex-space{display: flex;align-items: center;justify-content: space-between}
 .flex-end{display: flex;align-items: center;justify-content: flex-end}
 .ov-1x{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
 .ov-2x{text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
</style>
<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
