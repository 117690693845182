/****   request.js   ****/
// 导入axios
import axios from 'axios';
import qs from 'qs'
import Swal from "sweetalert2";
const service = axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: process.env.BASE_API,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 120 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
  if(config.type=='imgUp'){
    config.headers = {
      'Content-Type': 'multipart/form-data',
    }
  }else if(config.type=='postRequest'){
    config.headers = {
        "X-Requested-With":'XMLHttpRequest',
        'Content-Type': 'multipart/form-data',
    }
      config.data = qs.stringify({
          ...config.data,
          language:localStorage.language?localStorage.language:'cn',
          token: localStorage.token,
          user_id: localStorage.userId,
      }) //如果要求携带在参数中
  }else{
      config.headers = {
        "X-Requested-With":'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          // 'Content-Type': 'application/json'

          // 'Content-Type': 'multipart/form-data',
      }
      config.data = qs.stringify({
          ...config.data,
          language:localStorage.language?localStorage.language:'cn',
          token: localStorage.token,
          user_id: localStorage.userId,
      }) //如果要求携带在参数中
      // config.data = qs.stringify({...config.data,language:localStorage.language?localStorage.language:'cn',token: localStorage.token}) //如果要求携带在参数中

  }
  //如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
  //const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下


    return config
}, error => {
  Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    // console.log(response)
    if(loading!=''){
        loading.close();
        loading = '';
    }
    if(response.data.status==201){
        Swal.fire("请求失败,请稍后重试！");
        return
    }
  return response.data
  // Message.error('请求失败，请稍后再试！')
  // return Promise.resolve(response.data.msg)
  //接收到响应数据并成功后的一些共有的处理，关闭loading等
}, error => {
  /***** 接收到异常响应的处理开始 *****/
  if (error && error.response) {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
          Swal.fire("Error");
          break;
      case 401:
        // Message.error('未授权，请重新登录');
        break;
      case 403:
          Swal.fire("Error");
        break;
      case 404:
          Swal.fire("Error");
          // window.location.href = "/NotFound"
        break;
      case 405:
          Swal.fire("Error");
          break;
      case 408:
          Swal.fire("Error");
          break;
      case 500:
          Swal.fire("Error");
        break;
      case 501:
          Swal.fire("Error");
        break;
      case 502:
          Swal.fire("Error");
        break;
      case 503:
          Swal.fire("Error");
        break;
      case 504:
          Swal.fire("Error");
          break;
      case 505:
          Swal.fire("Error");
        break;
      case 508:
        window.location.href = '/erp/#/login'
        break;
      default:
        // Message.error(`连接错误${error.response.status}`);
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
        Swal.fire("Error");
      return
    }
      Swal.fire("Error");
  }

  // Message.error(error.message)
  /***** 处理结束 *****/
  //如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})
//4.导入文件
export default service
