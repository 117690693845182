import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {path: "/",name: "home",meta: {title: "home",authRequired: true,},component: () => import("../views/home/index.vue"),},
  {path: "/8CECA9F262A6356C059FFA1C5DA5072B",name: "8CECA9F262A6356C059FFA1C5DA5072B",meta: {title: "8CECA9F262A6356C059FFA1C5DA5072B",authRequired: true,},component: () => import("../views/home/home.vue"),},
  {path: "/canteen",name: "canteen",meta: {title: "canteen",authRequired: true,},component: () => import("../views/home/canteen.vue"),},
  {path: "/wineRoom",name: "wineRoom",meta: {title: "wineRoom",authRequired: true,},component: () => import("../views/home/wineRoom.vue"),},
  {path: "/maintenanceMenu",name: "maintenanceMenu",meta: {title: "maintenanceMenu",authRequired: true,},component: () => import("../views/home/maintenanceMenu.vue"),},
  {path: "/economics",name: "economics",meta: {title: "economics",authRequired: true,},component: () => import("../views/home/economics.vue"),},
  {path: "/club",name: "club",meta: {title: "club",authRequired: true,},component: () => import("../views/home/club.vue"),},
  {path: "/market",name: "market",meta: {title: "market",authRequired: true,},component: () => import("../views/home/market.vue"),},
  {path: "/userList",name: "userList",meta: {title: "userList",authRequired: true,},component: () => import("../views/user/userList.vue"),},
  {path: "/userMsg",name: "userMsg",meta: {title: "userMsg",authRequired: true,},component: () => import("../views/user/userMsg.vue"),},
  {path: "/Authority",name: "Authority",meta: {title: "Authority",authRequired: true,},component: () => import("../views/user/Authority.vue"),},
  {path: "/structure",name: "structure",meta: {title: "structure",authRequired: true,},component: () => import("../views/structure/index.vue"),},
  {path: "/structure-details",name: "structure-details",meta: {title: "structure-details",authRequired: true,},component: () => import("../views/structure/structure-details.vue"),},
  {path: "/address",name: "address",meta: {title: "address",authRequired: true,},component: () => import("../views/address/index.vue"),},
  {path: "/supplier",name: "supplier",meta: {title: "supplier",authRequired: true,},component: () => import("../views/supplier/index.vue"),},
  {path: "/GL",name: "GL",meta: {title: "GL",authRequired: true,},component: () => import("../views/GL/index.vue"),},
  {path: "/warehouse",name: "warehouse",meta: {title: "warehouse",authRequired: true,},component: () => import("../views/warehouse/index.vue"),},
  {path: "/warehouse-details",name: "warehouse-details",meta: {title: "warehouse-details",authRequired: true,},component: () => import("../views/warehouse/warehouse-details.vue"),},
  {path: "/purchase",name: "purchase",meta: {title: "purchase",authRequired: true,},component: () => import("../views/purchase/index.vue"),},
  {path: "/PO",name: "PO",meta: {title: "PO",authRequired: true,},component: () => import("../views/purchase/PO.vue"),},
  {path: "/purchase-add",name: "purchase-add",meta: {title: "purchase-add",authRequired: true,},component: () => import("../views/purchase/add.vue"),},
  {path: "/purchase-details",name: "purchase-details",meta: {title: "purchase-details",authRequired: true,},component: () => import("../views/purchase/purchase-details.vue"),},
  {path: "/products",name: "products",meta: {title: "products",authRequired: true,},component: () => import("../views/products/index.vue"),},
  {path: "/add-product",name: "add-product",meta: {title: "add-product",authRequired: true,},component: () => import("../views/products/add-product.vue"),},
  {path: "/product-details",name: "product-details",meta: {title: "product-details",authRequired: true,},component: () => import("../views/products/product-details.vue"),},
  {path: "/checkSheet",name: "checkSheet",meta: {title: "checkSheet",authRequired: true,},component: () => import("../views/checkSheet/index.vue"),},
  {path: "/APrun",name: "APrun",meta: {title: "APrun",authRequired: true,},component: () => import("../views/checkSheet/APrun.vue"),},
  {path: "/addAPrun",name: "addAPrun",meta: {title: "addAPrun",authRequired: true,},component: () => import("../views/checkSheet/add-APrun.vue"),},
  {path: "/add-checkSheet",name: "add-checkSheet",meta: {title: "add-checkSheet",authRequired: true,},component: () => import("../views/checkSheet/add-checkSheet.vue"),},
  {path: "/checkSheet-details",name: "checkSheet-details",meta: {title: "checkSheet-details",authRequired: true,},component: () => import("../views/checkSheet/checkSheet-details.vue"),},
  {path: "/stock",name: "stock",meta: {title: "stock",authRequired: true,},component: () => import("../views/stock/index.vue"),},
  {path: "/log",name: "log",meta: {title: "log",authRequired: true,},component: () => import("../views/stock/log.vue"),},
  {path: "/repertoryType",name: "repertoryType",meta: {title: "repertoryType",authRequired: true,},component: () => import("../views/stock/repertoryType.vue"),},
  {path: "/takeStock",name: "takeStock",meta: {title: "takeStock",authRequired: true,},component: () => import("../views/stock/takeStock.vue"),},
  {path: "/add-takeStock",name: "add-takeStock",meta: {title: "add-takeStock",authRequired: true,},component: () => import("../views/stock/add-takeStock.vue"),},
  {path: "/takeStock-details",name: "takeStock-details",meta: {title: "takeStock-details",authRequired: true,},component: () => import("../views/stock/takeStock-details.vue"),},
  {path: "/details",name: "details",meta: {title: "details",authRequired: true,},component: () => import("../views/stock/details.vue"),},
  {path: "/deposit",name: "deposit",meta: {title: "deposit",authRequired: true,},component: () => import("../views/stock/deposit.vue"),},
  {path: "/deposit-details",name: "deposit-details",meta: {title: "deposit-details",authRequired: true,},component: () => import("../views/stock/deposit-details.vue"),},
  {path: "/add-deposit",name: "add-deposit",meta: {title: "add-deposit",authRequired: true,},component: () => import("../views/stock/add-deposit.vue"),},
  {path: "/inventoryExit",name: "inventoryExit",meta: {title: "inventoryExit",authRequired: true,},component: () => import("../views/stock/inventoryExit.vue"),},
  {path: "/inventoryExit-details",name: "inventoryExit-details",meta: {title: "inventoryExit-details",authRequired: true,},component: () => import("../views/stock/inventoryExit-details.vue"),},
  {path: "/add-inventoryExit",name: "add-inventoryExit",meta: {title: "add-inventoryExit",authRequired: true,},component: () => import("../views/stock/add-inventoryExit.vue"),},
  {path: "/inventoryTransfer",name: "inventoryTransfer",meta: {title: "inventoryTransfer",authRequired: true,},component: () => import("../views/stock/inventoryTransfer.vue"),},
  {path: "/add-inventoryTransfer",name: "add-inventoryTransfer",meta: {title: "add-inventoryTransfer",authRequired: true,},component: () => import("../views/stock/add-inventoryTransfer.vue"),},
  {path: "/inventoryTransfer-details",name: "inventoryTransfer-details",meta: {title: "inventoryTransfer-details",authRequired: true,},component: () => import("../views/stock/inventoryTransfer-details.vue"),},
  {path: "/client",name: "client",meta: {title: "client",authRequired: true,},component: () => import("../views/client/index.vue"),},
  {path: "/add-client",name: "add-client",meta: {title: "add-client",authRequired: true,},component: () => import("../views/client/add-client.vue"),},
  {path: "/client-details",name: "client-details",meta: {title: "client-details",authRequired: true,},component: () => import("../views/client/client-details.vue"),},
  {path: "/order",name: "order",meta: {title: "order",authRequired: true,},component: () => import("../views/order/index.vue"),},
  {path: "/add-order",name: "add-order",meta: {title: "add-order",authRequired: true,},component: () => import("../views/order/add-order.vue"),},
  {path: "/order-details",name: "order-details",meta: {title: "order-details",authRequired: true,},component: () => import("../views/order/order-details.vue"),},
  {path: "/payment",name: "payment",meta: {title: "payment",authRequired: true,},component: () => import("../views/payment/index.vue"),},
  {path: "/tax",name: "tax",meta: {title: "tax",authRequired: true,},component: () => import("../views/tax/index.vue"),},
  {path: "/statement",name: "statement",meta: {title: "statement",authRequired: true,},component: () => import("../views/statement/index.vue"),},
  {path: "/maintenance",name: "maintenance",meta: {title: "maintenance",authRequired: true,},component: () => import("../views/maintenance/index.vue"),},
  {path: "/restaurant_kwh",name: "restaurant_kwh",meta: {title: "restaurant_kwh",authRequired: true,},component: () => import("../views/maintenance/restaurant_kwh.vue"),},
  {path: "/restaurant_gas",name: "restaurant_gas",meta: {title: "restaurant_gas",authRequired: true,},component: () => import("../views/maintenance/restaurant_gas.vue"),},
  {path: "/winery_kwh",name: "winery_kwh",meta: {title: "winery_kwh",authRequired: true,},component: () => import("../views/maintenance/winery_kwh.vue"),},
  {path: "/winery_gallons",name: "winery_gallons",meta: {title: "winery_gallons",authRequired: true,},component: () => import("../views/maintenance/winery_gallons.vue"),},
  {path: "/winery_gas",name: "winery_gas",meta: {title: "winery_gas",authRequired: true,},component: () => import("../views/maintenance/winery_gas.vue"),},
  {path: "/materialList",name: "materialList",meta: {title: "materialList",authRequired: true,},component: () => import("../views/materialList/index.vue"),},
  {path: "/material",name: "material",meta: {title: "material",authRequired: true,},component: () => import("../views/materialList/material.vue"),},
  {path: "/add-material",name: "add-material",meta: {title: "add-material",authRequired: true,},component: () => import("../views/materialList/add-material.vue"),},
  {path: "/addMaterialList",name: "addMaterialList",meta: {title: "addMaterialList",authRequired: true,},component: () => import("../views/materialList/addMaterialList.vue"),},
  {path: "/materialList-details",name: "materialList-details",meta: {title: "materialList-details",authRequired: true,},component: () => import("../views/materialList/materialList-details.vue"),},
  {path: "/material-details",name: "material-details",meta: {title: "material-details",authRequired: true,},component: () => import("../views/materialList/material-details.vue"),},
  {path: "/property",name: "property",meta: {title: "property",authRequired: true,},component: () => import("../views/property/index.vue"),},
  {path: "/add-property",name: "add-property",meta: {title: "add-property",authRequired: true,},component: () => import("../views/property/add-property.vue"),},
  {path: "/property-details",name: "property-details",meta: {title: "property-details",authRequired: true,},component: () => import("../views/property/property-details.vue"),},

  {path: "/assetSort",name: "assetSort",meta: {title: "assetSort",authRequired: true,},component: () => import("../views/assetSort/index.vue"),},
  {path: "/materialsClass",name: "materialsClass",meta: {title: "materialsClass",authRequired: true,},component: () => import("../views/assetSort/materials.vue"),},
  {path: "/materialsClass-son",name: "materialsClass-son",meta: {title: "materialsClass-son",authRequired: true,},component: () => import("../views/assetSort/materialsClass-son.vue"),},
  {path: "/wineBureau",name: "wineBureau",meta: {title: "wineBureau",authRequired: true,},component: () => import("../views/wineBureau/index.vue"),},
  {path: "/add-wineBureau",name: "add-wineBureau",meta: {title: "add-wineBureau",authRequired: true,},component: () => import("../views/wineBureau/add-wineBureau.vue"),},
  {path: "/wineBureau-details",name: "wineBureau-details",meta: {title: "wineBureau-details",authRequired: true,},component: () => import("../views/wineBureau/wineBureau-details.vue"),},
  {path: "/shareFile",name: "shareFile",meta: {title: "shareFile",authRequired: true,},component: () => import("../views/shareFile/index.vue"),},
  {path: "/add-shareFile",name: "add-shareFile",meta: {title: "add-shareFile",authRequired: true,},component: () => import("../views/shareFile/add-shareFile.vue"),},
  {path: "/shareFile-details",name: "shareFile-details",meta: {title: "add-shareFile",authRequired: true,},component: () => import("../views/shareFile/shareFile-details.vue"),},

  {path: "/olOrder",name: "olOrder",meta: {title: "olOrder",authRequired: true,},component: () => import("../views/olOrder/index.vue"),},
  {path: "/agriculture",name: "agriculture",meta: {title: "agriculture",authRequired: true,},component: () => import("../views/agriculture/index.vue"),},
  {path: "/agricultureList",name: "agricultureList",meta: {title: "agricultureList",authRequired: true,},component: () => import("../views/agriculture/list.vue"),},
  {path: "/add-caishou",name: "add-caishou",meta: {title: "add-caishou",authRequired: true,},component: () => import("../views/agriculture/add-caishou.vue"),},
  {path: "/add-shifei",name: "add-shifei",meta: {title: "add-shifei",authRequired: true,},component: () => import("../views/agriculture/add-shifei.vue"),},
  {path: "/caishou-details",name: "caishou-details",meta: {title: "caishou-details",authRequired: true,},component: () => import("../views/agriculture/caishou-details.vue"),},
  {path: "/shifei-detail",name: "shifei-detail",meta: {title: "shifei-detail",authRequired: true,},component: () => import("../views/agriculture/shifei-detail.vue"),},
  {path: "/yimiao-detail",name: "yimiao-detail",meta: {title: "yimiao-detail",authRequired: true,},component: () => import("../views/agriculture/yimiao-detail.vue"),},
  {path: "/add-yimiao",name: "add-yimiao",meta: {title: "add-yimiao",authRequired: true,},component: () => import("../views/agriculture/add-yimiao.vue"),},
  {path: "/add-qita",name: "add-qita",meta: {title: "add-qita",authRequired: true,},component: () => import("../views/agriculture/add-qita.vue"),},
  {path: "/qita-details",name: "qita-details",meta: {title: "qita-details",authRequired: true,},component: () => import("../views/agriculture/qita-details.vue"),},

  {path: "/promote",name: "promote",meta: {title: "promote",authRequired: true,},component: () => import("../views/promote/index.vue"),},
  {path: "/pour",name: "pour",meta: {title: "pour",authRequired: true,},component: () => import("../views/promote/pour.vue"),},
  {path: "/add-promote",name: "add-promote",meta: {title: "add-promote",authRequired: true,},component: () => import("../views/promote/add.vue"),},
  {path: "/promoteDetails",name: "promoteDetails",meta: {title: "promoteDetails",authRequired: true,},component: () => import("../views/promote/details.vue"),},
];