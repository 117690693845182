// import { createWebHistory, createRouter } from "vue-router";
import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import routes from './routes';
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...


export default router;
