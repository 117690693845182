import {} from "@/utils/allApi";
import check from "@/components/check.vue";
import pay from "@/components/pay.vue";

export default {
    components: {
        check,pay
    },
	data() {
		return {
            statementList:[
                {label:'statement1_1',val:'时间段销售数据对比报表'},
                {label:'statement1_2',val:'请款报表'},
                {label:'statement1_3',val:'APrun'},
                {label:'statement1_4',val:'promote'},
                {label:'stock-table5',val:'仓库'},
                {label:'statement1_6',val:'用酒'},
                {label:'statement1_7',val:'市场用酒数量报表'},
                {label:'statement1_8',val:'开瓶数量报表'},
            ],
            arrayOfCountries:[
                {code: "en",name: "English",cname: "英语",ename: "English",},
                {code: "zh-CN",name: "Chinese (Simplified)",cname: "中文 (简体)",ename: "Chinese (Simplified)",},
                {code: "zh-TW",name: "Chinese (Traditional)",cname: "中文 (繁体)",ename: "Chinese (Traditional)",},
            ],
            googleTranslateSelectedHandler:[
                {code: "en",name: "English",cname: "英语",ename: "English",},
                {code: "zh-CN",name: "Chinese (Simplified)",cname: "中文 (简体)",ename: "Chinese (Simplified)",},
                {code: "zh-TW",name: "Chinese (Traditional)",cname: "中文 (繁体)",ename: "Chinese (Traditional)",},
            ],
            isiPad:false,
            isCheck:false,
            isPay:false,
            isAdd:true,
            isEdit:true,
            isDelete:true,
            menuList:[],
            menuListConfig:[],
            menu:[
                // {id:'home',name:"t-home",icon:' las la-home',url:'/',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {
                    id:'home',name:"t-home",
                    icon:'las la-home',
                    url:'',rank:2,
                    list:[
                        {id:'boss',name:"t-boss",icon:'',url:'/',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'wineRoom',name:"t-wineRoom",icon:'',url:'/wineRoom',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'canteen',name:"t-canteen",icon:'',url:'/canteen',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'economics',name:"t-economics",icon:'',url:'/economics',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'club',name:"t-club",icon:'',url:'/club',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'market',name:"t-market",icon:'',url:'/market',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'maintenanceMenu',name:"t-maintenanceMenu",icon:'',url:'/maintenanceMenu',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                    ],
                    isShow:true,isAdd:true,isEdit:true,isDelete:true
                },
                {id:'products',name:"t-products",icon:'ri-apps-line',url:'/products',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {id:'order',name:"t-order",icon:'ri-file-list-3-line',url:'/order',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {id:'olOrder',name:"olOrder",icon:'ri-file-list-3-line',url:'/olOrder',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {id:'client',name:"t-client",icon:'ri-user-6-line',url:'/client',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                // {id:'checkSheet',name:"t-checkSheet",icon:'ri-money-dollar-circle-line',url:'/checkSheet',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {
                    id:'checkSheet',name:"t-checkSheet",
                    icon:'ri-money-dollar-circle-line',
                    url:'',rank:2,
                    list:[
                        {id:'reimbursement',name:"t-reimbursement",icon:'ri-archive-drawer-line',url:'/checkSheet',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'APrun',name:"APrun",icon:'ri-archive-drawer-line',url:'/APrun',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        // {id:'deposit',name:"t-deposit",icon:'ri-logout-circle-line',url:'/deposit',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                    ],
                    isShow:true,isAdd:true,isEdit:true,isDelete:true
                },
                {id:'purchase',name:"t-purchase",icon:'ri-todo-line',url:'/purchase',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {id:'wineBureau',name:"t-wineBureau",icon:'bx bx-wine',url:'/wineBureau',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                // {id:'promote',name:"t-promote",icon:'las la-warehouse',url:'/promote',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {
                    id:'promote',name:"t-promote",
                    icon:'las la-warehouse',
                    url:'',rank:2,
                    list:[
                        {id:'promoteList',name:"t-promote1",icon:'ri-archive-drawer-line',url:'/promote',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'pour',name:"t-pour",icon:'ri-archive-drawer-line',url:'/pour',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                    ],
                    isShow:true,isAdd:true,isEdit:true,isDelete:true
                },
                {
                    id:'entrepot',name:"t-entrepot",
                    icon:'ri-hotel-line',
                    url:'',rank:2,
                    list:[
                        {id:'stock',name:"t-stock",icon:'ri-archive-drawer-line',url:'/stock',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'log',name:"t-log",icon:'ri-archive-drawer-line',url:'/log',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'takeStock',name:"t-takeStock",icon:'ri-logout-circle-line',url:'/takeStock',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'deposit',name:"t-deposit",icon:'ri-logout-circle-line',url:'/deposit',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'inventoryExit',name:"t-inventoryExit",icon:'ri-logout-circle-r-line',url:'/inventoryExit',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'inventoryTransfer',name:"t-inventoryTransfer",icon:'las la-shipping-fast',url:'/inventoryTransfer',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                    ],
                    isShow:true,isAdd:true,isEdit:true,isDelete:true
                },
                {id:'maintenances',name:"t-maintenance",icon:'ri-hammer-line',url:'/maintenance',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {
                    id:'materialPage',name:"t-material",
                    icon:'ri-settings-3-line',
                    url:'',rank:2,
                    list:[
                        {id:'material',name:"t-material",icon:'las la-briefcase',url:'/material',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'materialList',name:"t-materialList",icon:'las la-briefcase',url:'/materialList',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                    ],
                    isShow:true,isAdd:true,isEdit:true,isDelete:true
                },
                {id:'property',name:"t-property",icon:' ri-refund-2-line',url:'/property',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {id:'shareFile',name:"t-shareFile",icon:' ri-folder-4-fill',url:'/shareFile',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {id:'statement',name:"t-statement",icon:'las la-warehouse',url:'/statement',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {id:'agriculture',name:"t-agriculture",icon:'las la-warehouse',url:'/agriculture',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                {
                    id:'set',name:"t-set",
                    icon:'ri-settings-3-line',
                    url:'',rank:2,
                    list:[
                        {id:'repertoryType',name:"t-repertoryType",icon:'las la-briefcase',url:'/repertoryType',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'structure',name:"t-structure",icon:'las la-briefcase',url:'/structure',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'warehouse',name:"t-warehouse",icon:'las la-warehouse',url:'/warehouse',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'address',name:"t-address",icon:'las la-city',url:'/address',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'user',name:"t-user",icon:' ri-group-line',url:'/userList',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'payment',name:"t-payment",icon:'las la-warehouse',url:'/payment',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'tax',name:"t-tax",icon:'las la-warehouse',url:'/tax',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'GL',name:"t-GL",icon:'las la-warehouse',url:'/GL',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'supplier',name:"t-supplier",icon:'las la-warehouse',url:'/supplier',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'assetSort',name:"t-assetSort",icon:'las la-warehouse',url:'/assetSort',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                        {id:'materialsClass',name:"t-materialsClass",icon:'las la-warehouse',url:'/materialsClass',rank:1,isShow:true,isAdd:true,isEdit:true,isDelete:true},
                    ],
                    isShow:true,isAdd:true,isEdit:true,isDelete:true
                },
            ],

			// 全局混入，插入链接参数
            page: 1,//当前页分页
            rows: 20,//分页条数
            list:[],
            sum_page:1,

            timeFun: null,
            loading: null,
            isCode: true,
            codeTime: 59,
            totalPage: 1,
            pageSize: 30,
            info:null,//用户个人信息
            // defaultLogo:require('../assets/defaultLogo.png'),//默认头像
            imgCodeKey:Math.floor(Math.random()*10000)+1,
            datetimeConfig: {
                enableTime: false,
                dateFormat: "d-m-Y",
                time_24hr: false,
            },
            tax:['0%','GST 5%','PST 7%','HST 13%'],
            tax1:['0%','GST 5%','PST 7%','HST 12%','HST 13%'],
            taxList:[
                {val:'5',label:'GST 5%'},
                {val:'7',label:'PST 7%'},
                {val:'13',label:'HST 13%'},
            ],
            paymentType:[
                {value:'信用卡',label:'paymentType1'},
                {value:'支付宝',label:'paymentType2'},
                {value:'微信',label:'paymentType3'},
            ],
            orderPayType:[
                {value:'信用卡',label:'paymentType1'},
                {value:'邮件链接',label:'orderPayType2'},
            ],
            taxProvince:[
                { value: '艾伯塔省', label: 'taxProvince1' },
                { value: 'BC省', label: 'taxProvince2' },
                { value: '马尼托巴省', label: 'taxProvince3' },
                { value: '新不伦瑞克省', label: 'taxProvince4' },
                { value: '纽芬兰和拉布拉多省', label: 'taxProvince5' },
                { value: '新斯科舍省', label: 'taxProvince6' },
                { value: '努纳武特地区', label: 'taxProvince7' },
                { value: '安大略省', label: 'taxProvince8' },
                { value: '爱德华王子岛省', label: 'taxProvince9' },
                { value: '魁北克省', label: 'taxProvince10' },
                { value: '萨斯喀彻温省', label: 'taxProvince11' },
                { value: '西北地区和育空地区', label: 'taxProvince12' },
            ],

            productClass:[
                // { value: '酒水', label: '酒水' },
                // { value: '服装', label: '服装' },
                // { value: '电子产品', label: '电子产品' },
            ],
            // 农业状态  健康，生病，在观察，等待采收，已采收，已死亡
            agricultureType:[
                {label:'agricultureType1',val:1},
                {label:'agricultureType2',val:2},
                {label:'agricultureType3',val:3},
                {label:'agricultureType4',val:4},
                {label:'agricultureType5',val:5},
                {label:'agricultureType6',val:6},
            ],
            // promote 用途 1样品 2活动 3礼物 4员工福利 5市场 6其他
            useList:[
                {id:'1',name:'promoteType1'},
                {id:'2',name:'promoteType2'},
                {id:'3',name:'promoteType3'},
                {id:'4',name:'promoteType4'},
                {id:'5',name:'promoteType5'},
                {id:'6',name:'promoteType6'},
            ]

        }
	},
	computed: {},
    watch: {
        isiPad() {
            // this.isiPad = this.isiPad
            console.log(this.isiPad); //浏览器窗口变化时，打印宽度。
        },
    },
    mounted() {
        // let self = this;

        if(localStorage.page) {
            this.page = Number(localStorage.page);
            this.$nextTick(()=>{
                localStorage.removeItem('page')
            })
        }
        let menuList = localStorage.userInfo&&JSON.parse(localStorage.userInfo).menu;
        menuList&&menuList.forEach(item=>{
            item.isShow = JSON.parse(item.isShow);
            if(item.rank==2){
                item.list.forEach(data=>{
                    data.isShow = JSON.parse(data.isShow);
                })
            }
        })
        this.menuList = menuList?menuList:this.menuListConfig;
        // this.menuList = this.menu;
    },
	methods: {
        /**
         * 深拷贝对象
         * @param {要拷贝对象} obj
         */
        copyObject(obj){
            let str,
                newobj = Array.isArray(obj) === true ? [] : {};
            if (typeof obj !== "object") {
                return;
            } else if (JSON) {
                (str = JSON.stringify(obj)), //系列化对象
                    (newobj = JSON.parse(str)); //还原
            } else {
                for (let i in obj) {
                    newobj[i] = typeof obj[i] === "object" ? copyObject(obj[i]) : obj[i];
                }
            }
            return newobj;
        },
        ThousandWithNumber(value){
            if (!value) return 0
            // 获取整数部分
            const wholePart = Math.trunc(value)
            // 梳理数据 -> 千分位
            const wholePartFormat = wholePart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
            // 处理小数部分
            let decimalPart = ''
            // 将数值截取为小数部分和整数部分
            const valueArray = value.toString().split('.')
            if (valueArray.length === 2) { // 有小数部分
                decimalPart = valueArray[1].toString() // 取得小数部分
                // console.log(decimalPart.substring(0,2),'-----------')
                return wholePartFormat + '.' + decimalPart.substring(0,2)
            }
            return wholePartFormat + decimalPart
        },
        isiPadFun(){
            if(750<document.body.clientWidth&&document.body.clientWidth<1400){
                this.isiPad = true
            }else{
                this.isiPad = false
            }
            window.onresize = () => {
                return (() => {
                    // let clientWidth = document.body.clientWidth;
                    if(750<document.body.clientWidth&&document.body.clientWidth<1400){
                        this.$nextTick(()=>{
                            this.isiPad = true
                        })
                    }else{
                        this.$nextTick(()=>{
                            this.isiPad = false
                        })
                    }
                    console.log(this.isiPad)
                })()
            }
        },
        changeGoogleLanguage(type){
            console.log(type)
            let select = document.querySelector('.goog-te-combo');
            select.value = type;
            select.dispatchEvent(new Event('change'));
            localStorage.googleLanguage = type
        },
        googleTranslateSelectedHandler(language) {
            const { code, name, cname, ename } = language
            // todo ...
        },
        toOpenPage(url){
            let origin = window.location.origin
            let pathname = window.location.pathname
            url = origin + pathname + '#' + url
            window.open (url, "page", "height=1000, width=1200, top=100, left=300,toolbar=no, menubar=no, scrollbars=no, resizable=no, loca tion=no, status=no")
        },
        toPage(url){
            let origin = window.location.origin
            let pathname = window.location.pathname
            window.open (url, "page", "height=1000, width=1200, top=100, left=300,toolbar=no, menubar=no, scrollbars=no, resizable=no, loca tion=no, status=no")
        },
        //下载音频
        downloadFileFun(url,name){
            const link = document.createElement('a'); //创建下载a标签
            link.href = url;
            link.download = name; //下载后文件名
            link.style.display = 'none'; //默认隐藏元素
            document.body.appendChild(link); // body中添加元素
            link.click(); // 执行点击事件
            URL.revokeObjectURL(link.href); //释放Blob对象
            document.body.removeChild(link);//下载完成后移除元素
        },
        showCheckFun(item){
            this.arguments.id = item.id
            this.arguments.check_id = item.check_id
            this.isCheck = true;
        },
        payCheckFun(item){
            this.arguments.id = item.id
            this.arguments.check_id = item.check_id
            this.isPay = true;
        },
        AuthorityFun(type,type2){
            this.menuList.forEach(res=>{
                if(type==res.id){
                    if(type2){
                        res.list.forEach(item=>{
                            if(type2==item.id){
                                this.isAdd = JSON.parse(item.isAdd);
                                this.isEdit = JSON.parse(item.isEdit);
                                this.isDelete = JSON.parse(item.isDelete);
                            }
                        })
                    }else{
                        this.isAdd = JSON.parse(res.isAdd);
                        this.isEdit = JSON.parse(res.isEdit);
                        this.isDelete = JSON.parse(res.isDelete);
                    }

                }
            })
        },
        rowsFun(e){
            this.$emit('previousPageFun',1,'pageSize',this.rows)
        },
	    //分页
        previousPageFun(e,type,rows){
            this.page = e;
            if(type=='pageSize'){this.rows = rows}
            if(type=='+'){this.page++}
            if(type=='-') {this.page--}
            this.list = [];
            this.getListFun();
        },
        resetFun(){
            this.page = 1;
            this.list = [];
        },
        getTimeFun(type = "yyyy-MM-dd HH:mm:ss",date = new Date().getTime()){
          const formatNumber = (n) => {
            const str = n.toString();
            return str[1] ? str : `0${str}`;
          };
          date = date ? date : new Date().getTime()
          typeof date === 'string' ? date =  date.replace(/-/g,'/') : '';
          date = typeof date === "object" ? date : new Date(date);
          let year = date.getFullYear(),
            month = date.getMonth() + 1,
            day = date.getDate(),

            hour = date.getHours(),
            minute = date.getMinutes(),
            second = date.getSeconds(),

            t0 = date.getTime(),
            t1 = [year, month, day].map(formatNumber).join("-"),
            t2 = [year, month, day].map(formatNumber).join("/"),
            t3 = [hour, minute, second].map(formatNumber).join(":"),

            newTime = "";
          switch (type) {
            case "yyyy-MM-dd HH:mm:ss":
              newTime = `${t1} ${t3}`;
              break;
            case "yyyy/MM/dd HH:mm:ss":
              newTime = `${t2} ${t3}`;
              break;
            case "yyyy-MM-dd":
              newTime = t1;
              break;
            case "yyyy":
              newTime = year;
              break;
            case "MM":
              newTime = month;
              break;
            case "dd":
              newTime = day;
              break;
            case "HH":
              newTime = hour;
              break;
            case "mm":
              newTime = minute;
              break;
            case "ss":
              newTime = second;
              break;
            case "yyyy-MM":
              newTime = `${year}-${month}`;
              break;
            case "yyyy/MM/dd":
              newTime = t2;
              break;
            case "timeStamp-13":
              newTime = t0;
              break;
            case "timeStamp-10":
              newTime = String(t0).substr(0,10);
              break;
            default:
              newTime = `${t1} ${t3}`;
              break;
          }
          return newTime;


        },

        getPreMonth(date) {
            var arr = date.split('-');
            var year = arr[0]; //获取当前日期的年份
            var month = arr[1]; //获取当前日期的月份
            var day = arr[2]; //获取当前日期的日
            var days = new Date(year, month, 0);
            days = days.getDate(); //获取当前日期中月的天数
            var year2 = year;
            var month2 = parseInt(month) - 1;
            if (month2 == 0) {
                year2 = parseInt(year2) - 1;
                month2 = 12;
            }
            var day2 = day;
            var days2 = new Date(year2, month2, 0);
            days2 = days2.getDate();
            if (day2 > days2) {
                day2 = days2;
            }
            if (month2 < 10) {
                month2 = '0' + month2;
            }
            var t2 = year2 + '-' + month2;
            return t2;
        },
        /**
         * 图片上传
         * @parmas
         * **/
        upUserLogo(){
            let self = this
            let promise = new Promise((resolve, reject)=> {
                uni.chooseImage({
                    count: 1, //默认9
                    sizeType: ['original', 'compressed'], //可以指定是原图还是压缩图，默认二者都有
                    success: function (res) {
                        uni.uploadFile({
                            url: '/api/'+'user/control/updateAvatar',
                            filePath: res.tempFilePaths[0],
                            name: 'imgFile',
                            header: {
                                // "X-Requested-With":'XMLHttpRequest',
                                "cms_token": Cookies.get("cms_token"),
                                "cms_accessToken": Cookies.get("cms_accessToken"),
                                "cms_refreshToken": Cookies.get("cms_refreshToken"),
                                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                            },
                            formData: {
                                dir:'image',
                                width:200,
                                height:200,
                                fileName:res.tempFiles[0].name
                            },
                            success: (uploadFileRes) => {
                                let img = JSON.parse(uploadFileRes.data)
                                resolve(img.data.url);
                            }
                        });
                    }
                });
            })
            return promise

        },
        upImg(){
            let self = this
            let promise = new Promise((resolve, reject)=> {
                uni.chooseImage({
                    count: 1, //默认9
                    sizeType: ['original', 'compressed'], //可以指定是原图还是压缩图，默认二者都有
                    success: function (res) {
                        uni.uploadFile({
                            url: '/api/'+'user/control/question/upload',
                            filePath: res.tempFilePaths[0],
                            name: 'file',
                            header: {
                                // "X-Requested-With":'XMLHttpRequest',
                                "cms_token": Cookies.get("cms_token"),
                                "cms_accessToken": Cookies.get("cms_accessToken"),
                                "cms_refreshToken": Cookies.get("cms_refreshToken"),
                                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                            },
                            formData: {
                                dir:'image',
                                fileName:res.tempFiles[0].name
                            },
                            success: (uploadFileRes) => {
                                let img = JSON.parse(uploadFileRes.data)
                                // resolve(baseUrl+img.url);
                                resolve(img.url);
                            }
                        });
                    }
                });
            })
            return promise

        },
        upVideo(){
            let self = this
            let promise = new Promise((resolve, reject)=> {
                uni.chooseVideo({
                    sourceType: ['album', 'camera'],
                    success: function (res) {
                        console.log(res)
                        console.log(res.tempFile)
                        uni.uploadFile({
                            url: '/api/'+'user/control/topic/upload',
                            filePath: res.tempFilePath,
                            name: 'file',
                            header: {
                                // "X-Requested-With":'XMLHttpRequest',
                                "cms_token": Cookies.get("cms_token"),
                                "cms_accessToken": Cookies.get("cms_accessToken"),
                                "cms_refreshToken": Cookies.get("cms_refreshToken"),
                                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                            },
                            formData: {
                                dir:'media',
                                fileName:res.name
                            },
                            success: (uploadFileRes) => {
                                let video = JSON.parse(uploadFileRes.data)
                                resolve(video.data.url);
                            }
                        });
                    }
                });
            })
            return promise

        },

        toPageFun(url){
            this.$router.push({ path: url})
        },
	},

}
