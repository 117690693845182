<template>
<div>
    <div class="modal fade show" style="display: block;background: rgba(0,0,0,0.6);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">{{$t('checkSheet-table6')}}</h5>
                    <button type="button" class="btn-close" @click="closeFun"></button>
                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col-lg-12 mb-3" >
                            <label class="form-label">{{$t('remark')}}</label>
                            <textarea class="form-control" v-model="remark"></textarea>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <div class="hstack gap-2 justify-content-end">
                        <button type="button" class="btn btn-light" @click="closeFun" >
                            {{$t('close')}}
                        </button>
                        <button type="button" class="btn btn-success" id="add-btn" @click="checkFun">
                            {{$t('confirm')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import Swal from "sweetalert2";
    import {editPaystatus} from "@/utils/allApi";
    export default {
        data() {
            return {
                checkVal:'NONE',
                remark:'',
                userGroup:'',
            };
        },
        props:{
            arguments:{
                type: Object,
                default:{}
            }
        },
        mounted() {
            this.userGroup = JSON.parse(localStorage.userInfo).user_group
        },
        methods: {
            closeFun(){
                this.$emit('close')
            },
            // type ‘qingkuan’请款审批，’ruku’入库审批，’chuku’ 入库审批 ，’zhuanyi’ 库存转移审批’caigou’ 采购单审批
            checkFun(){
                // if(this.checkVal=="NONE"){
                //     Swal.fire(this.$t('tooltip1'));
                //     return;
                // }
                editPaystatus({
                    id:this.arguments.id,
                    pay_status:'已付款',
                    check_beizhu:this.remark,
                }).then(res=>{
                    this.$emit('succeedFun')
                    // window.location.reload();
                })
            },

        },
    };
</script>
