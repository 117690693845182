/****   http.js   ****/
// 导入封装好的axios实例
import Swal from "sweetalert2";
import request from './request'

const http ={
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url,params){
    const config = {
      method: 'get',
      url:url
    }
    if(params) config.params = params
    let promise = new Promise((resolve, reject)=> {
      request(config).then(res=>{
        if(res.code&&res.code==209){
            Swal.fire('去登陆');
            window.location.href = '/erp/#/login'
            return
        }
        if(res.code&&res.code!=200){
            Swal.fire(res.msg);
          return
        }

        resolve(res)
      })
    })
    return promise
    // return request(config)
  },
  post(url,params){
    const config = {
      method: 'post',
      url:url
    }
    if(params) config.data = params
    let promise = new Promise((resolve, reject)=> {
      request(config).then(res=>{
        if(res.code&&res.code==209){
            window.location.href = '/erp/#/login'
            return
        }
        if(res.code&&res.code!=200){
            Swal.fire(res.msg);
          return
        }
        resolve(res)
      })
    })
    return promise
  },
  postRequest(url,params){
    const config = {
      method: 'post',
      type:'postRequest',
      url:url
    }
    if(params) config.data = params
    let promise = new Promise((resolve, reject)=> {
      request(config).then(res=>{
        if(res.code&&res.code!=200){
            Swal.fire(res.msg);
            return
        }
        resolve(res)
      })
    })
    return promise
  },
  upImgPost(url,params){
        const config = {
            method: 'post',
            type:'imgUp',
            url:url
        }
        if(params) config.data = params
        let promise = new Promise((resolve, reject)=> {
            request(config).then(res=>{
                if(res.code&&res.code==209){
                    window.location.href = '/erp/#/login'
                    return
                }
                if(res.code&&res.code!=200){
                    Swal.fire(res.msg);
                    return
                }
                resolve(res)
            })
        })
        return promise
        // return request(config)
  },
  httpRequest(url,params,type){
    const config = {
      method: type,
      url:url
    }
    if(params) config.params = params
    let promise = new Promise((resolve, reject)=> {
      request(config).then(res=>{
        if(res.code&&res.code!=200){
            Swal.fire(res.msg);
          return
        }
        resolve(res)
      })
    })
    return promise
    // return request(config)
  },
}
//导出
export default http
